import React, { Component } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import theme from "Constants/theme";
import { Tooltip } from "@madecomfy/webooi";

import iconBed from "./assets/bed.svg";
import iconBird from "./assets/keyNest.svg";
import iconBuilding from "./assets/building.svg";
import iconCamera from "./assets/camera.svg";
import iconClean from "./assets/cleaningSpray.svg";
import iconClock from "./assets/clock.svg";
import iconEye from "./assets/eye.svg";
import iconGuest from "./assets/person.svg";
import iconHouse from "./assets/house.svg";
import iconMoon from "./assets/moon.svg";
import iconParkingEnabled from "./assets/car.svg";
import iconParkingDisabled from "./assets/noCar.svg";
import iconPetsEnabled from "./assets/paw.svg";
import iconPetsDisabled from "./assets/noPaw.svg";
import iconPro from "./assets/pro.svg";
import iconReport from "./assets/report.svg";
import iconShower from "./assets/shower.svg";
import iconSuspended from "./assets/suspended.svg";
import iconWarning from "./assets/warning.svg";
import iconWifiEnabled from "./assets/wifi.svg";
import iconWifiDisabled from "./assets/noWifi.svg";

import MoreInfoDots from "Components/MoreInfoDots";
import propertyPlaceholder from "./assets/propertyPlaceholder.svg";
import {
  getStatus,
  getCleanStatus,
  getKeyStatus,
  getKeyStatusColor,
} from "./PropertyConstants";
import { IconText } from "Components/Specifications";
import { formatDateWithFullYear, titleCase } from "Utils";
import { Body, FinePrint, H6 } from "Components/Typography";

import Icon from "Components/Icon";
import { Stars } from "Containers/Booking/BookingReview/Review";
import { STRATooltip } from "Containers/EventsCalendar/CalendarPolicy/STRATooltip";

interface IProps {
  floating: boolean;
  handleClick?: (...args: any) => any;
  height?: number;
  isContentCreator: boolean;
  isLicensee: boolean;
  isOwner: boolean;
  isStaff: boolean;
  properties: any[];
  showButtons: boolean;
  showPropertyListPage: boolean;
}

const PropertyTableWrapper = styled.div<any>`
  ${({ floating }) =>
    floating &&
    css`
      overflow-x: hidden;
      overflow-y: auto;
    `};
  margin: 24px;
  ${({ height }) => height && `height: ${height - 100}px`};
  @media (max-width: ${({ theme }) => theme.maxTablet}) {
    //  overflow: auto;
  }
`;

const PropertyItem = styled.div<any>`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing.spacingSM};
  transition: background 0.05s linear;
  background: ${({ status }) => (status === "onboarding" ? "#EFF8F3" : "#fff")};
  border-radius: 8px;
  margin-bottom: 8px;
  @media (max-width: ${({ theme }) => theme.maxTablet}) {
    display: block;
    position: relative;
    div[data-test="more-info"] {
      position: absolute;
      top: 8px;
    }
  }
`;

const ColAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 6px 0;
`;

const ColButton = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: end;
  align-self: flex-start;
  gap: 16px;
`;

const PropertyImage = styled.img`
  height: 91px;
  margin: 0 16px;
  width: 91px;
  border-radius: 8px;
  object-fit: cover;
  @media (max-width: ${({ theme }) => theme.maxTablet}) {
    display: none;
  }
`;
const Name = styled.p`
  width: 317px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #303336;
  font-family: InterSemiBold;
  font-size: 16px;
  white-space: nowrap;
  margin: 0;
  @media (max-width: ${({ theme }) => theme.maxTablet}) {
    width: auto;
  }
`;
const Text = styled.p`
  width: 317px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #939296;
  font-family: InterMedium;
  font-size: 14px;
  white-space: nowrap;
  margin: 0;
  @media (max-width: ${({ theme }) => theme.maxTablet}) {
    width: auto;
  }
`;
const White = styled.p`
  text-align: left;
  margin: 0;
  color: ${({ color, theme }) => color || theme.text.inverse} !important;
`;
const Icons = styled.div<any>`
  display: flex;
  div {
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
  }
  p {
    color: ${({ color, theme }) => color || theme.text.default};
    font-size: 14px;
    line-height: 18px;
  }
  img {
    margin-right: 4px;
  }
  svg {
    display: flex;
    padding-top: 4px;
    margin-right: -4px;
  }
  @media (max-width: ${({ theme }) => theme.maxTablet}) {
    ${(props) =>
      props["data-test"] === "first-row" &&
      `
      grid-column: span 6;
  `}
    ${(props) =>
      props["data-test"] === "first-row-end" &&
      `
    grid-column: span 6;
    justify-self: end;
`}
${(props) =>
      props["data-test"] === "onboarding-icons" &&
      `
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px !important;
`}
  }
`;
const IconsContainer = styled.div<any>`
  display: flex;
  gap: 16px;
  div:first-of-type {
    div:first-of-type {
      margin-left: 0;
    }
  }
  div[data-test="tooltip"] {
    bottom: 42px;
  }
  @media (max-width: ${({ theme }) => theme.maxTablet}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: 16px;
    ${(props) =>
      props["data-test"] === "onboarding" &&
      `
      display: flex;
      justify-content: end;
  `}
    div {
      margin: 0;
      gap: 8px;
    }
  }
`;
const Flex = styled.div`
  display: flex;
  @media (max-width: ${({ theme }) => theme.maxTablet}) {
    display: block;
  }
`;
const FlexMobile = styled.div`
  display: flex;
  @media (max-width: ${({ theme }) => theme.maxTablet}) {
    justify-content: space-between;
  }
`;
const StatusPill = styled.div<any>`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  border-radius: 4px;
  margin: 0 16px;
  background: ${({ segment, theme }) =>
    new Map([
      ["essential", theme.background.accentTeal],
      ["essentials", theme.background.accentTeal],
      ["comfort", theme.background.accentTealBold],
      ["premium", theme.background.accentYellow],
      ["luxe", theme.background.accentMagenta],
    ]).get(segment)};
  color: ${({ theme }) => theme.text.inverse};
  height: 26px;
  @media (max-width: ${({ theme }) => theme.maxTablet}) {
    margin: 0;
  }
`;
const CleanStatusPill = styled.div<any>`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  border-radius: 4px;
  margin: 8px 8px 8px 0;
  background: ${({ status, theme }) =>
    new Map([
      ["blocked", theme.background.alert],
      ["dirty", theme.background.warning],
      ["in_progress", theme.background.warning],
      ["occupied", theme.background.subtle2],
      ["ready", theme.background.success],
    ]).get(status)};
  span {
    color: ${({ status, theme }) =>
      new Map([
        ["blocked", theme.text.alert],
        ["dirty", theme.text.warning],
        ["in_progress", theme.text.warning],
        ["occupied", theme.text.subtle],
        ["ready", theme.text.success],
      ]).get(status)};
  }
  @media (max-width: ${({ theme }) => theme.maxTablet}) {
    ${(props) =>
      props["data-test"] === "start" &&
      `
  grid-column: 3 / span 7;
  justify-self: flex-start;
  width: max-content;
`}
  }
`;
const KeyStatusPill = styled.div<any>`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  border-radius: 4px;
  margin: 8px 8px 8px -16px;
  position: relative;
  gap: 4px;
  width: max-content;
  background: ${({ status, theme }) =>
    new Map([
      ["Unknown", theme.background.alert],
      ["In Use", theme.background.info],
      ["Key in place", theme.background.success],
      ["Cleaner confirmed key is in place", theme.background.warning],
      ["Waiting for drop off", theme.background.warning],
      ["In Store", theme.background.success],
    ]).get(status)};
  span {
    color: ${({ color }) => color};
    margin-left: ${({ type }) => (type === "key-nest" ? "0px" : "24px")};
  }
  svg {
    position: absolute;
    top: 4px;
  }
  div[data-test="tooltip"] {
    bottom: 32px;
  }
`;
const List = styled.div`
  padding: 8px;
  margin-bottom: 4px;
  h6 {
    margin: 0;
  }
  &:hover {
    background: ${({ theme }) => theme.background.whiteHover};
    border-radius: 4px;
    cursor: pointer;
  }
`;
const WrapperTooltip = styled.div`
  position: absolute;
  top: 0px;
  left: 8px;
  width: 100%;
  z-index: 9;
  div[data-test="tooltip"] {
    width: auto;
    z-index: 9;
    svg {
      display: none;
    }
  }
`;
const WrapperName = styled.div`
  position: relative;
  @media (max-width: ${({ theme }) => theme.maxTablet}) {
    margin-left: 32px;
    margin-top: -10px;
    margin-bottom: 16px;
    margin-right: 48px;
  }
`;
const OwnerName = styled.div`
  position: relative;
`;
const Names = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  p,
  h6 {
    text-align: right;
  }
  h6 {
    font-weight: 500;
  }
  div[data-test="tooltip"] {
    width: max-content;
    opacity: 0.8;
    p {
      text-align: left;
      margin: 0;
    }
  }
  @media (max-width: ${({ theme }) => theme.maxTablet}) {
    flex-direction: row-reverse;
    align-items: center;
    gap: 16px;
  }
`;
const Ratings = styled.div`
  display: flex;
  align-items: center;
  margin: -8px 0;
  gap: 4px;
  position: relative;
  div {
    font-size: 16px;
    margin: 0;
  }
  img {
    margin-left: 4px;
  }
`;
const OnboardingStatusPill = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  background: ${({ theme }) => theme.background.success};
  span {
    color: ${({ theme }) => theme.text.success};
  }
`;
const Pro = styled.img`
  margin-top: 16px;
  @media (max-width: ${({ theme }) => theme.maxTablet}) {
    margin-top: 0;
  }
`;
const Risk = styled.div`
  position: relative;
  display: flex;
  gap: 4px;
`;
const STRA = styled.div<any>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: bottom;
  margin-top: 16px;
  margin-left: -16px;
  background: ${({ color, theme }) =>
    new Map([
      ["red", theme.background.alertBold],
      ["green", theme.background.successBold],
      ["orange", theme.background.warningBold],
      ["yellow", theme.background.accentYellow],
    ]).get(color)};
`;
const STRAWrapperTooltip = styled.div`
  position: absolute;
  top: 12px;
  left: -18px;
  width: 100%;
  z-index: 9;
`;
const WrapperSTRA = styled.div`
  position: relative;
  div[data-test="tooltip"] {
    width: 145px;
    bottom: 36px;
  }
`;
const Mobile = styled.div`
  @media (max-width: ${({ theme }) => theme.maxTablet}) {
    position: absolute;
    div {
      margin: 0;
    }
  }
`;
const Line = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.maxTablet}) {
    display: block;
    border-top: 1px solid #e9e9ea;
    margin: 16px -16px 16px -16px;
  }
`;

class PropertyTable extends Component<IProps> {
  render() {
    const {
      floating,
      handleClick,
      height,
      isContentCreator,
      isLicensee,
      isOwner,
      isStaff,
      properties,
      showButtons,
      showPropertyListPage,
    } = this.props;
    return (
      <PropertyTableWrapper
        floating={floating}
        height={height}
        data-test="property-table-list"
      >
        {properties.map((property) => {
          const {
            address,
            airbnbOverallRating,
            amenities = [],
            checkpoints = {},
            compliance,
            createdAt,
            company = {},
            details = {},
            id,
            //  isActive,
            //  isTest,
            keyStatus,
            keyType,
            overallCleaningStatus,
            owners,
            primaryPhoto,
            resources,
            riskStatus,
            name,
            //  slug,
            status,
            tasksSummary = {},
          } = property;
          const {
            entryCleanDate,
            ingoingInspectionDate,
            photographyDate,
            readyReportDate,
          } = checkpoints;
          const { name: companyName } = company;
          const { bathrooms, bedrooms, maxGuests, minimumStay, type, segment } =
            details;
          const {
            maintenanceOverdue,
            inspectionOverdue,
            maintenanceTasks,
            inspectionTasks,
          } = tasksSummary;
          const isPetsAllowed =
            amenities &&
            amenities.filter(
              ({ code }: { code: string }) => code === "pets-allowed",
            );
          const isWifi =
            amenities &&
            amenities.filter(
              ({ code }: { code: string }) => code === "wireless-internet",
            );
          const isParking =
            amenities &&
            amenities.filter(
              ({ code }: { code: string }) =>
                code === "free-parking-on-premises",
            );
          const propertyImage = primaryPhoto
            ? `https://img.madecomfy.com.au/width/300/n/${primaryPhoto}`
            : propertyPlaceholder;
          const breezewayURL = resources?.links?.find(
            ({ name }: { [key: string]: any }) => name === "Breezeway",
          );
          const salesforceURL = resources?.links?.find(
            ({ name }: { [key: string]: any }) => name === "Salesforce",
          );
          const openTask =
            maintenanceOverdue || inspectionOverdue
              ? "#DE2910"
              : inspectionTasks === 0 && maintenanceTasks === 0
              ? "#3033364d"
              : "#303336";
          const today = dayjs();
          const onboardedDate =
            createdAt && today.diff(dayjs(createdAt), "day");

          return (
            <div key={id}>
              {showPropertyListPage ? (
                <PropertyItem
                  data-test="property-item"
                  key={id}
                  onClick={(event: any) =>
                    handleClick && handleClick(event, property)
                  }
                  status={status}
                >
                  <Mobile>
                    <IconText
                      alt={titleCase(status)}
                      src={getStatus.get(status)}
                    />
                  </Mobile>
                  <PropertyImage data-test="property-img" src={propertyImage} />
                  <div>
                    <Link
                      to={
                        isContentCreator
                          ? `/properties/${id}/details/the-property`
                          : `/properties/${id}`
                      }
                    >
                      <ColAddress>
                        <div>
                          <Flex>
                            <WrapperName>
                              <Name data-test="name">{name}</Name>
                              <WrapperTooltip>
                                <Tooltip
                                  hideIcon={true}
                                  position="top"
                                  text={name}
                                  testId="tooltip"
                                  timeOut={100}
                                />
                              </WrapperTooltip>
                              <Text data-test="address">{address}</Text>
                            </WrapperName>
                            {status !== "onboarding" && (
                              <FlexMobile>
                                <StatusPill segment={segment}>
                                  <FinePrint white semi as="span">
                                    {titleCase(segment)}
                                  </FinePrint>
                                </StatusPill>
                                <Link to={`/properties/${id}/reviews`}>
                                  <Ratings>
                                    <Stars rating={airbnbOverallRating} />
                                    <FinePrint semi>
                                      {airbnbOverallRating}
                                    </FinePrint>
                                    <WrapperTooltip>
                                      <Tooltip
                                        hideIcon={true}
                                        position="top"
                                        text="Airbnb rating"
                                        testId="tooltip"
                                        timeOut={100}
                                      />
                                    </WrapperTooltip>
                                    {!isOwner &&
                                      riskStatus &&
                                      (riskStatus === "at-risk" ||
                                        riskStatus === "Suspended") && (
                                        <Risk>
                                          <img
                                            src={
                                              riskStatus === "Suspended"
                                                ? iconSuspended
                                                : iconWarning
                                            }
                                            alt="risk"
                                          />
                                          <FinePrint
                                            color={
                                              riskStatus === "Suspended"
                                                ? theme.text.alert
                                                : theme.text.warning
                                            }
                                          >
                                            {riskStatus === "Suspended"
                                              ? "Suspended"
                                              : "At risk"}
                                          </FinePrint>
                                          <WrapperTooltip>
                                            <Tooltip
                                              hideIcon={true}
                                              position="top"
                                              text={
                                                riskStatus === "Suspended"
                                                  ? "Suspended by Airbnb"
                                                  : "At risk of Airbnb suspension"
                                              }
                                              testId="tooltip"
                                              timeOut={100}
                                            />
                                          </WrapperTooltip>
                                        </Risk>
                                      )}
                                  </Ratings>
                                </Link>
                              </FlexMobile>
                            )}
                          </Flex>
                        </div>
                      </ColAddress>
                    </Link>
                    <Line />
                    {status === "onboarding" ? (
                      <IconsContainer data-test="onboarding">
                        <Icons data-test="onboarding-icons">
                          {ingoingInspectionDate && (
                            <IconText
                              alt="Ingoing inspection"
                              src={iconEye}
                              text={formatDateWithFullYear(
                                ingoingInspectionDate,
                              )}
                            />
                          )}
                          {entryCleanDate && (
                            <IconText
                              alt="Entry clean"
                              src={iconClean}
                              text={formatDateWithFullYear(entryCleanDate)}
                            />
                          )}
                          {photographyDate && (
                            <IconText
                              alt="Photography"
                              src={iconCamera}
                              text={formatDateWithFullYear(photographyDate)}
                            />
                          )}
                          {readyReportDate && (
                            <IconText
                              alt="Property ready report"
                              src={iconReport}
                              text={formatDateWithFullYear(readyReportDate)}
                            />
                          )}
                          {onboardedDate && (
                            <OnboardingStatusPill>
                              <img src={iconClock} alt="onboarding" />
                              <FinePrint semi as="span">
                                {`${onboardedDate} days in onboarding`}
                              </FinePrint>
                            </OnboardingStatusPill>
                          )}
                        </Icons>
                      </IconsContainer>
                    ) : (
                      <IconsContainer>
                        <Icons data-test="first-row">
                          <IconText
                            alt={titleCase(type)}
                            src={type === "house" ? iconHouse : iconBuilding}
                          />
                          <IconText
                            alt="Bedroom"
                            src={iconBed}
                            text={bedrooms?.toString()}
                          />
                          <IconText
                            alt="Bathroom"
                            src={iconShower}
                            text={bathrooms?.toString()}
                          />
                          <IconText
                            alt="Max guests"
                            src={iconGuest}
                            text={maxGuests?.toString()}
                          />
                          <IconText
                            alt="Min nights"
                            src={iconMoon}
                            text={minimumStay?.toString()}
                          />
                        </Icons>
                        {compliance?.policyEnabled && (
                          <WrapperSTRA>
                            <STRA color={compliance?.riskColour} />
                            <STRAWrapperTooltip>
                              <Tooltip
                                hideIcon={true}
                                position="bottom"
                                text={<STRATooltip compliance={compliance} />}
                                testId="tooltip"
                                timeOut={100}
                              />
                            </STRAWrapperTooltip>
                          </WrapperSTRA>
                        )}
                        <Icons data-test="first-row-end">
                          <IconText
                            alt={isPetsAllowed?.length > 0 ? "Pets" : "No pets"}
                            src={
                              isPetsAllowed?.length > 0
                                ? iconPetsEnabled
                                : iconPetsDisabled
                            }
                          />
                          <IconText
                            alt={
                              isParking?.length > 0 ? "Parking" : "No parking"
                            }
                            src={
                              isParking?.length > 0
                                ? iconParkingEnabled
                                : iconParkingDisabled
                            }
                          />
                          <IconText
                            alt={isWifi?.length > 0 ? "Wifi " : "No wifi"}
                            src={
                              isWifi?.length > 0
                                ? iconWifiEnabled
                                : iconWifiDisabled
                            }
                          />
                        </Icons>
                        {!isOwner && (
                          <>
                            <Icons
                              color={openTask}
                              onClick={() => window.open(breezewayURL?.url)}
                            >
                              <IconText
                                alt={
                                  <div style={{ color: "#fff" }}>
                                    <White>
                                      <b>
                                        {inspectionTasks === 0 &&
                                        maintenanceTasks === 0
                                          ? "No open tasks"
                                          : "Open tasks"}
                                      </b>
                                    </White>
                                    {maintenanceTasks > 0 && (
                                      <White
                                        color={maintenanceOverdue && "#DE2910"}
                                      >
                                        {maintenanceTasks} Maintenance
                                      </White>
                                    )}
                                    {inspectionTasks > 0 && (
                                      <White
                                        color={inspectionOverdue && "#DE2910"}
                                      >
                                        {inspectionTasks} Inspection
                                      </White>
                                    )}
                                  </div>
                                }
                                src={
                                  <Icon
                                    type="opentask"
                                    size={24}
                                    fill={openTask}
                                  />
                                }
                                text={(
                                  inspectionTasks + maintenanceTasks
                                ).toString()}
                              />
                            </Icons>
                          </>
                        )}
                        <CleanStatusPill
                          data-test="start"
                          status={overallCleaningStatus}
                        >
                          <FinePrint semi as="span">
                            {getCleanStatus.get(overallCleaningStatus) as any}
                          </FinePrint>
                        </CleanStatusPill>
                        {keyType && keyStatus && (
                          <>
                            <KeyStatusPill
                              status={keyStatus}
                              type={keyType}
                              color={getKeyStatusColor.get(keyStatus)}
                            >
                              {keyType === "key-nest" ? (
                                <img src={iconBird} alt="key-nest" />
                              ) : (
                                <Icon
                                  type="key"
                                  size={24}
                                  fill={getKeyStatusColor.get(keyStatus) as any}
                                />
                              )}
                              <FinePrint semi as="span">
                                {getKeyStatus.get(keyStatus) as any}
                              </FinePrint>
                              {keyType === "key-nest" && (
                                <WrapperTooltip>
                                  <Tooltip
                                    hideIcon={true}
                                    position="bottom"
                                    text="KeyNest"
                                    testId="tooltip"
                                    timeOut={100}
                                  />
                                </WrapperTooltip>
                              )}
                              {keyType === "standard" && (
                                <>
                                  {keyStatus ===
                                    "Cleaner confirmed key is in place" && (
                                    <img src={iconWarning} alt="warning" />
                                  )}
                                  <WrapperTooltip>
                                    <Tooltip
                                      hideIcon={true}
                                      position="bottom"
                                      text={
                                        keyStatus ===
                                        "Cleaner confirmed key is in place"
                                          ? "Verification needed"
                                          : "Key status"
                                      }
                                      testId="tooltip"
                                      timeOut={100}
                                    />
                                  </WrapperTooltip>
                                </>
                              )}
                            </KeyStatusPill>
                          </>
                        )}
                      </IconsContainer>
                    )}
                  </div>
                  {showButtons && (
                    <ColButton>
                      <Names>
                        <div>
                          <Body semi>{companyName}</Body>
                          <OwnerName>
                            <H6 color="#939296">
                              {owners && owners.length
                                ? `${owners[0].name} ${
                                    owners.length > 1
                                      ? ` + ${owners.length - 1}`
                                      : ""
                                  }`
                                : ""}
                            </H6>
                            {owners && owners.length > 1 && (
                              <WrapperTooltip>
                                <Tooltip
                                  hideIcon={true}
                                  position="bottom"
                                  text={owners.map(
                                    ({ name }: any, i: number) => (
                                      <p key={i}>{name}</p>
                                    ),
                                  )}
                                  testId="tooltip"
                                  timeOut={100}
                                />
                              </WrapperTooltip>
                            )}
                          </OwnerName>
                        </div>
                        {isStaff && companyName && (
                          <Pro src={iconPro} alt="pro" />
                        )}
                      </Names>
                      <MoreInfoDots
                        align="left"
                        links={
                          [
                            {
                              label: "View calendar",
                              link: `/properties/${id}`,
                            },
                            !isLicensee &&
                              !isOwner && {
                                label: "Edit property details",
                                link: `/properties/${id}/details`,
                              },
                            /* isLicensee &&
                              !isOwner &&
                              isActive && {
                                label: "View listing",
                                link: `https://madecomfy.com.au/properties/${slug}/${
                                  isTest ? "?flag=testmode" : ""
                                }`,
                                newWindow: true,
                              }, */
                            !isOwner && {
                              label: "Manage tasks",
                              link: `${breezewayURL?.url}`,
                              newWindow: true,
                            },
                            !isLicensee &&
                              !isOwner && {
                                label: "Salesforce",
                                link: `${salesforceURL?.url}`,
                                newWindow: true,
                              },
                          ].filter(Boolean) as any
                        }
                      />
                    </ColButton>
                  )}
                </PropertyItem>
              ) : (
                <List
                  onClick={(event: any) =>
                    handleClick && handleClick(event, property)
                  }
                  key={id}
                >
                  <Link to={floating ? `/properties/${id}` : {}}>
                    <Body bold>{name}</Body>
                    <H6 color={theme.text.subtle}>{address}</H6>
                  </Link>
                </List>
              )}
            </div>
          );
        })}
      </PropertyTableWrapper>
    );
  }
}

export default PropertyTable;
