import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Body, H5 } from "Components/Typography";
import { ALERT_SUCCESS, Alert, BUTTON_INLINE, Button } from "@madecomfy/webooi";

import Loading from "Components/Loading";
import { userImpersonate } from "Actions/users/userImpersonate";

const Wrapper = styled.div`
  padding: 24px;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.maxMobile}) {
    width: 327px;
  }
`;

const Title = styled(H5)`
  font-weight: 600;
  color: ${({ theme }) => theme.darkPetrol};
  margin: 0 0 16px;
`;

const DescWrapper = styled.div`
  position: relative;
`;

const TextWithButton = styled.div`
  margin: 16px 0;
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 48px;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.charcoalLight50};
  border-radius: 4px;
  padding-left: 16px;
  justify-content: space-between;
  button {
    box-shadow: none !important;
    margin: 0 !important;
    padding: 12px 9px;
    border-radius: 0px 4px 4px 0px;
    width: 111px;
    @media screen and (max-width: ${({ theme }) => theme.maxMobile}) {
      &:hover {
        background-color: ${({ theme }) => theme.navy}!important;
      }
    }
  }
`;

const TextView = styled.div`
  max-width: calc(100% - 111px);
  line-height: normal;
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
  }
`;

const WrapperAlert = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1000;
  & > div {
    margin: 0;
    padding: 8px;
    background: ${({ theme }) => theme.white};
    border: none;
    box-shadow: 0px 20px 40px 0px rgba(53, 55, 60, 0.1);
    border-radius: 4px;
    svg {
      width: 24px;
      height: 24px;
    }
    & > div {
      &:last-child {
        padding: 0 0 0 8px;
      }
    }
  }
`;

interface IProps {
  name?: string;
  id: string;
}

export const LogInAsPopup: React.FC<IProps> = ({ name, id }) => {
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);

  const [showCopySuccessAlert, setShowCopySuccessAlert] = useState(false);
  useEffect(() => {
    const asyncLoadAvailable = async () => {
      setLoading(true);
      const resp = await userImpersonate(id);
      if (resp?.errorCode) {
        setLoading(false);
        return;
      }
      setLink(resp);
      setLoading(false);
    };
    asyncLoadAvailable();
  }, []);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    setShowCopySuccessAlert(true);
    setTimeout(() => {
      setShowCopySuccessAlert(false);
    }, 1200);
  };

  return (
    <Wrapper data-test="loginas-popup">
      {loading && <Loading task="restoringVoucher" />}

      <Title>Log in as {name}</Title>
      <DescWrapper>
        <Body data-test="loginas-text">
          In order to be able to log in as {name} you need to{" "}
          <strong>open another browser</strong> or{" "}
          <strong>new window in Incognito mode</strong> and paste the below URL
          into the address bar.
        </Body>
        {showCopySuccessAlert && (
          <WrapperAlert data-test="copy-to-clipboard-alert">
            <Alert
              message="Copied to clipboard"
              testId="alert-copy-success"
              type={ALERT_SUCCESS}
            />
          </WrapperAlert>
        )}
      </DescWrapper>
      <TextWithButton>
        <TextView>
          <Body>{link}</Body>
        </TextView>
        <Button
          testId="copy"
          label="Copy"
          styling={BUTTON_INLINE}
          onClick={copyToClipboard}
        />
      </TextWithButton>
    </Wrapper>
  );
};
