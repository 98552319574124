const API_URL = "https://api.madecomfy.com.au/api/";
const CHECKOUT_URL = "https://book.madecomfy.com/";
const ENV = "production";
const FEATURE_FLAGS = [];
const GTM_ID = "GTM-5JMKWHN";
const REDUX_LOGGING = false;
const SENTRY_DSN =
  "https://57d330e31c164ce682019e7061d0dde6@o101065.ingest.sentry.io/222045";
module.exports = {
  API_URL,
  CHECKOUT_URL,
  ENV,
  FEATURE_FLAGS,
  GTM_ID,
  REDUX_LOGGING,
  SENTRY_DSN,
};
