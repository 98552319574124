// all fields required for a property
export default [
  "address",
  "amenities",
  "base-fees",
  "channels",
  "company",
  "compliance",
  "details",
  "finance",
  "keys",
  "managers",
  "management-fees",
  "merchant",
  "photos",
  "policy",
  "pricing-exclusions",
  "resources",
  "rooms",
  "settings",
  "users",
];
