import { API_URL } from "Config";
import { EDIT_USER } from "Constants/actionTypes";
import { BAD_REQUEST, generateErrorHandler, patchJSON } from "Utils/Network";
import { linkCompany, linkProperty } from "./userCreate";
import { logException } from "ThirdParty";
import { UPDATE as mode } from "Constants/crud";

const handleError = generateErrorHandler(EDIT_USER.ERROR);

export function userUpdate(
  userCode: string,
  userDetails: { [key: string]: any },
  properties: string | any[] | null | undefined,
  companies: string | any[] | null | undefined,
) {
  return async (dispatch: (...args: any) => any) => {
    try {
      dispatch({ mode, type: EDIT_USER.PENDING, status: true });
      if (userDetails) {
        // if userDetails is null we are just updating the properties.
        await patchJSON(`${API_URL}v3/users/${userCode}`, userDetails, {
          errorCodes: [BAD_REQUEST],
        });
      }
      if (properties && properties.length) {
        await linkProperty(
          userCode,
          "owner", // can be "strata"
          properties,
          false,
        );
      }
      if (companies && companies.length) {
        await linkCompany(userCode, false, companies);
      }
      dispatch({ mode, type: EDIT_USER.SUCCESS });
    } catch (err: any) {
      logException("userUpdate", EDIT_USER);
      // eslint-disable-next-line no-console
      console.warn(err);
      dispatch(handleError(err));
    }
  };
}

export function userMeUpdate(userDetails: { [key: string]: any }) {
  return async (dispatch: (...args: any) => any) => {
    try {
      dispatch({ mode, type: EDIT_USER.PENDING, status: true });
      await patchJSON(`${API_URL}v3/users/me`, userDetails, {
        errorCodes: [BAD_REQUEST],
      });
      dispatch({ mode, type: EDIT_USER.SUCCESS });
    } catch (err: any) {
      logException("userMeUpdate", EDIT_USER);
      // eslint-disable-next-line no-console
      console.warn(err);
      dispatch(handleError(err));
    }
  };
}
