import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";

import UserSearch from "./UserSearch";
import UserAdd from "./UserAdd";
import UserEdit from "./UserEdit";

import Loading from "Components/Loading";

interface IProps {
  history: any;
  match: { [key: string]: any };
  resetCreate: (...args: any) => any;
  resetGet: (...args: any) => any;
  userCreate: (...args: any) => any;
  userRestore: (...args: any) => any;
  userRolesRestore: (...args: any) => any;
  user: { [key: string]: any };
  users: { [key: string]: any };
  usersRestore: (...args: any) => any;
  userUpdate: (...args: any) => any;
}

class UserManager extends Component<IProps> {
  componentDidMount() {
    this.props.userRolesRestore();
  }

  render() {
    const {
      match,
      resetCreate,
      resetGet,
      userCreate,
      userRestore,
      user,
      users,
      usersRestore,
      userUpdate,
    } = this.props;

    const { code } = match.params;
    const { editUserPermissions, editUserStatus, userHistory } = users;
    const { pending, success, roles: editUserRoles } = editUserPermissions;
    if (pending || success === false) {
      // need to load the logged in user's permissions (ie roles) first
      return <Loading task="editingUserPermissions" />;
    }
    return (
      <Router {...this.props}>
        <Switch>
          <Route
            exact
            path="/users/update/:code"
            render={() => (
              <UserEdit
                code={code}
                editUserStatus={editUserStatus}
                editUserRoles={editUserRoles}
                reset={resetGet}
                userRestore={userRestore}
                userUpdate={userUpdate}
              />
            )}
          />
          <Route
            exact
            path="/users/create"
            render={() => (
              <UserAdd
                editUserRoles={editUserRoles}
                editUserStatus={editUserStatus}
                reset={resetCreate}
                userCreate={userCreate}
              />
            )}
          />
          <Route
            exact
            path="/users"
            render={() => (
              <UserSearch
                user={user}
                userHistory={userHistory}
                usersRestore={usersRestore}
                userUpdate={userUpdate}
              />
            )}
          />
        </Switch>
      </Router>
    );
  }
}

export default UserManager;
