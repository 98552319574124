import React from "react";
import Config from "Config";
import styled from "styled-components";
import { ROLE } from "Constants/userRoles";

const Banner = styled.div`
  background: orangered;
  color: white;
  text-align: center;
  font-size: large;
  padding: 6px 2px;
  height: 40px;
  position: sticky;
  top: 0px;
  z-index: 100;
}
`;

const isProd = Config.ENV === ("production" as string);

interface IProps {
  user?: {
    roles: any[];
    impersonated?: boolean;
  };
}

export const showProductionBanner = ({ user }: IProps) =>
  isProd && user && user?.roles?.includes(ROLE.DEVELOPER);

const ProductionBanner = ({ user }: IProps) => {
  if (showProductionBanner({ user })) {
    return (
      <Banner>
        You are currently on <strong>PRODUCTION</strong>!
        {user?.impersonated &&
          ` This is an impersonation session, so proceed carefully with your actions.`}
      </Banner>
    );
  } else if (user?.impersonated) {
    return (
      <Banner>
        This is an impersonation session, so proceed carefully with your
        actions.
      </Banner>
    );
  }
  return <></>;
};

export default ProductionBanner;
