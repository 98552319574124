import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { userCreate, resetCreate } from "Actions/users/userCreate";
import { userRestore, resetGet } from "Actions/users/userRestore";
import { userRolesRestore } from "Actions/users/userRolesRestore";
import { usersRestore } from "Actions/users/usersRestore";
import { userUpdate } from "Actions/users/userUpdate";

import UserManager from "./UserManager";

import withSessionCheck from "HOC/withSessionCheck";

const mapStateToProps = (state: {[key: string]: any}) => ({
  users: state.users,
  user: state?.authentication?.sessionStatus?.user,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  resetCreate: bindActionCreators(resetCreate, dispatch),
  resetGet: bindActionCreators(resetGet, dispatch),
  userCreate: bindActionCreators(userCreate, dispatch),
  userRestore: bindActionCreators(userRestore, dispatch),
  userRolesRestore: bindActionCreators(userRolesRestore, dispatch),
  usersRestore: bindActionCreators(usersRestore, dispatch),
  userUpdate: bindActionCreators(userUpdate, dispatch),
});

const UserManagerConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserManager);

export default withSessionCheck(UserManagerConnected);

export { UserManagerConnected };
