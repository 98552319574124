import { API_URL } from "Config";
import { CURRENT_PROPERTY } from "Constants/actionTypes";
import { formatUserName } from "Utils";
import { getJSON, generateErrorHandler, generateURL } from "Utils/Network";
import { RESTORE as mode } from "Constants/crud";

import include from "./includesForProperty";

const handleError = generateErrorHandler(CURRENT_PROPERTY.ERROR);

export function propertyRestore(
  propertyId: string,
  type?: { notStore: boolean },
) {
  return async (dispatch: (...args: any) => any) => {
    try {
      if (!type?.notStore) {
        dispatch({ mode, type: CURRENT_PROPERTY.PENDING, status: true });
      }
      const url = generateURL(`${API_URL}v3/properties/${propertyId}`, {
        include,
      });
      const json = await getJSON(url);
      const currentProperty = flattenProperty(json);
      const users = getPropertyUsers(json);
      if (!Object.prototype.hasOwnProperty.call(currentProperty, "keys")) {
        currentProperty.keys = [];
      }
      if (type?.notStore) {
        return {
          currentProperty: {
            ...currentProperty,
            users,
          },
        };
      }
      dispatch({
        currentProperty: {
          ...currentProperty,
          users, // users here overwrites currentProperty.users and currentProperty.managers
        },
        mode,
        type: CURRENT_PROPERTY.SUCCESS,
      });
    } catch (err: any) {
      // eslint-disable-next-line
      console.warn("propertyRestore error", err);
      dispatch(handleError(err));
    }
  };
}

export function resetPropertyState() {
  return (dispatch: (...args: any) => any) => {
    dispatch({ mode, type: CURRENT_PROPERTY.RESET });
  };
}

// extract `accountManager` and `lastLoginAt` from `users` and `managers`
const getPropertyUsers = (json: { [key: string]: any }) => {
  const users = (json?.included || []).reduce(
    (
      acc: { [key: string]: any },
      { attributes, id, type }: { [key: string]: any },
    ) => {
      const {
        firstName,
        lastName,
        email,
        fullName,
        lastLoginAt,
        managerType,
        photoUrl,
        roles,
      } = attributes;
      switch (type) {
        case "manager":
          const managers = acc.managers.concat([
            { email, fullName, type: managerType, id },
          ]);
          // managerType can be `sales` or `customer_experience`
          if (managerType === "customer_experience") {
            return {
              ...acc,
              accountManager: { fullName, photoUrl },
              managers,
            };
          }
          return { ...acc, managers };
        case "user":
          const owners = acc.owners.concat([
            {
              email,
              fullName: formatUserName({ firstName, lastName }),
              lastLoginAt,
              type: roles && roles.join(", "),
              id,
            },
          ]);
          if (lastLoginAt > acc.lastLoginAt) {
            return { ...acc, lastLoginAt, owners };
          }
          return { ...acc, owners };
        default:
        // ignoring anything other than `manager` and `user`
      }
      return acc;
    },
    {
      accountManager: null,
      lastLoginAt: "",
      managers: [],
      owners: [],
    },
  );
  return users;
};

/*
turn API V3 jsonapi response into flat object like:
{
  address: {full: "1/10 Docker Street, Elwood, VIC, 3184", street: "1/10 Docker Street" …}
  agency: "MadeComfy Melbourne"
  channels: [{…}, {…}, {…}, {…}, {…}, {…}]
  code: "5a466c3465ffb"
  createdAt: "2017-12-29T16:24:20+00:00"
  details: {description: "Come and stay in our beach oasis only 6km from Mel …}
  isActive: true
  managers: [{…}, {…}]
  modifiedAt: "2019-12-03T05:42:44+00:00"
  name: "Ultra Modern Large 1 Bedroom Apartment"
  resources: {links: […]}
  shortCode: "110"
  slug: "ultra-modern-large-1-bedroom-apartment"
  users: {accountManager: {…}, lastLoginAt: "2019-11-27T …}
}
*/
export const flattenProperty = ({ data, included }: { [key: string]: any }) => {
  const { attributes, relationships } = data;
  const flattened = Object.entries(relationships).reduce(
    (acc, relationship: any) => {
      const [key, relDetails] = relationship;
      // console.log(key, relDetails);
      if (!key || !relDetails || !relDetails.data) return acc; // some items are empty arrays.

      // find the relevant included data...
      if (relDetails.data.length) {
        // within an array...
        const items = relDetails.data.map(
          ({ type: rt, id: ri }: { [key: string]: any }) => {
            return included.find(({ type, id }: { [key: string]: any }) => {
              return type === rt && id === ri;
            });
          },
        );
        return { ...acc, [key]: items };
      }

      // or just a single item
      const item = included.find(({ type, id }: { [key: string]: any }) => {
        return type === relDetails.data.type && id === relDetails.data.id;
      });
      if (!item || !item.attributes) return acc; // couldn't find the item...

      // some attributes are nested with their own key,
      // eg. details is `item.attributes` others eg. amenities are `item.attributes.amenities`
      const value = item.attributes[key] || item.attributes;
      if (item.type === "merchant-account") {
        value.merchantAccountId = item.id;
      }
      return { ...acc, [key]: value };
    },
    {} as any,
  );

  // special flag for hostfully, search for: HOSTFULLY_SPECIFIC
  const hostfullyLink =
    flattened.resources &&
    flattened.resources.links &&
    flattened.resources.links.find(
      (resource: { [key: string]: any }) => resource.name === "Hostfully",
    );
  const hostfullyUrl = (hostfullyLink && hostfullyLink.url) || "";
  const resourcesUrls = hostfullyUrl ? { resourcesUrls: { hostfullyUrl } } : {};

  return {
    ...attributes,
    ...flattened,
    ...resourcesUrls, // HOSTFULLY_SPECIFIC
  };
};
