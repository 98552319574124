// this file is shared with property list STRA tooltip
import dayjs from "dayjs";
import React from "react";
import styled, { css } from "styled-components";

import { Body, FinePrint } from "Components/Typography";
import { formatDate } from "Utils";
import { ICompliance } from "./compliance.type";

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
`;

const TooltipTitle = styled(FinePrint)<{
  $padTop?: boolean;
}>`
  margin: 0;
  display: inline-block;
  white-space: nowrap;
  color: ${({ theme }) => theme.white};
  font-weight: 700;
  margin-bottom: 4px;
  &[data-test="day-remaining-title"] {
    margin-bottom: 8px;
  }
  ${(p) =>
    p?.$padTop &&
    css`
      margin-top: 16px;
    `}
`;

const TooltipValueWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const TooltipText = styled(Body)`
  margin: 0;
  display: inline-block;
  white-space: nowrap;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.white};
  font-weight: 400;
`;

const RiskWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const Square = styled.div<{
  color: string;
}>`
  width: 8px;
  height: 8px;
  background: ${({ color, theme }) =>
    new Map([
      ["red", theme.background.alertBold],
      ["green", theme.background.successBold],
      ["orange", theme.background.warningBold],
      ["yellow", theme.background.accentYellow],
    ]).get(color)};
`;

export const STRATooltip = ({ compliance }: { compliance: ICompliance }) => {
  const activated = compliance?.currentYearOverrideActivated;
  const today = dayjs();
  const currentYearRenewalDate = dayjs(compliance?.currentYearRenewalDate);
  const daysUntilRenewal = currentYearRenewalDate.diff(today, "day");
  const renewalDate = formatDate(compliance?.currentYearRenewalDate);

  return (
    <TooltipContent>
      <TooltipTitle>Renewal</TooltipTitle>
      <TooltipText data-test="tooltip-renewal">{renewalDate}</TooltipText>
      <TooltipTitle $padTop>Booking override</TooltipTitle>
      <TooltipText>
        {activated ? "Long-term stays only" : "All stays accepted"}
      </TooltipText>
      <TooltipTitle $padTop data-test="day-remaining-title">
        Days remaining
      </TooltipTitle>
      <TooltipValueWrap>
        <TooltipText>Bookable days left</TooltipText>
        <TooltipText data-test="bookable-days-left">
          {compliance?.currentYearBookableDays || "-"}
        </TooltipText>
      </TooltipValueWrap>
      <TooltipValueWrap>
        <TooltipText>Days until renewal</TooltipText>
        <TooltipText data-test="days-until-renewal">
          {daysUntilRenewal || "-"}
        </TooltipText>
      </TooltipValueWrap>
      <TooltipValueWrap>
        <TooltipText>Risk level</TooltipText>
        <RiskWrap>
          {compliance?.riskColour && (
            <Square color={compliance?.riskColour} data-test="risk-color" />
          )}
          <TooltipText data-test="risk-label">
            {compliance?.riskLabel || "-"}
          </TooltipText>
        </RiskWrap>
      </TooltipValueWrap>
    </TooltipContent>
  );
};
