import { API_URL } from "Config";
import {
  BAD_REQUEST,
  INTERNAL_SERVER_ERROR,
  UNPROCESSABLE_ENTITY,
  generateErrorHandler,
  getJSON,
} from "Utils/Network";

const handleError = generateErrorHandler("USER_IMPERSONATE_ERROR");

export const userImpersonate = async (userId: string) => {
  try {
    const json = await getJSON(
      `${API_URL}v3/users/${userId}/impersonate`,
      {
        errorCodes: [BAD_REQUEST, UNPROCESSABLE_ENTITY, INTERNAL_SERVER_ERROR],
      },
    );
    return json?.data?.attributes?.url;
  } catch (err: any) {
    // eslint-disable-next-line no-console
    console.warn("userImpersonate err", err);
    return handleError(err);
  }
};
