import { API_URL } from "Config";
import {
  BAD_REQUEST,
  generateErrorHandler,
  NetworkError,
  postJSON,
} from "Utils/Network";
import { CREATE as mode } from "Constants/crud";
import { EDIT_USER } from "Constants/actionTypes";
import { logException } from "ThirdParty";

const handleError = generateErrorHandler(EDIT_USER.ERROR);

export function userCreate(
  userDetails: { [key: string]: any },
  properties: string | any[] | null | undefined,
  companies: string | any[] | null | undefined,
) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: EDIT_USER.PENDING, mode, status: true });
      const json = await postJSON(`${API_URL}v3/users`, userDetails, {
        errorCodes: [BAD_REQUEST],
      });
      if (properties && properties.length) {
        await linkProperty(
          json.data.id,
          "owner", // can be "strata"
          properties,
          false,
        );
      }
      if (companies && companies.length) {
        await linkCompany(json.data.id, false, companies);
      }
      dispatch({ mode, type: EDIT_USER.SUCCESS });
    } catch (err: any) {
      logException("userCreate", EDIT_USER);
      dispatch(handleError(err));
    }
  };
}

export const linkProperty = async (
  userCode: string | number,
  mappingType: string,
  propertyCodes: string | any[],
  fullMapping: boolean,
) => {
  // cannot use postJSON here - response is empty.
  const response = await fetch(`${API_URL}v3/users/${userCode}/properties`, {
    mode: "cors",
    credentials: "include",
    method: "POST",
    body: JSON.stringify({ propertyCodes, mappingType, fullMapping }),
  });
  if (!response.ok) {
    throw new NetworkError(response);
  }
  return true;
};

export const linkCompany = async (
  userCode: string | number,
  fullMapping: boolean,
  companyIds: string | any[],
) => {
  const response = await fetch(`${API_URL}v3/users/${userCode}/companies`, {
    mode: "cors",
    credentials: "include",
    method: "POST",
    body: JSON.stringify({ companyIds, fullMapping }),
  });
  if (!response.ok) {
    throw new NetworkError(response);
  }
  return true;
};

export function resetCreate() {
  return (dispatch: (...args: any) => any) => {
    dispatch({ mode, type: EDIT_USER.RESET });
  };
}
