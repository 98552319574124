import React, { useState, Fragment, useEffect } from "react";
import styled, { css } from "styled-components";

import PropertyList from "Containers/PropertyList";
import { H4 } from "Components/Typography";
import { Button, BUTTON_INLINE, BUTTON_SMALL } from "@madecomfy/webooi";
import { DeletePopup } from "./DeletePopup";
import { linkProperty } from "Actions/users/userCreate";

const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.charcoalLight50};
  color: ${({ theme }) => theme.charcoal};
`;

const List = styled.div`
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.charcoalLight50};
  margin-bottom: 16px;
`;

const PropertyItem = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.navyLight50};
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  margin: 4px;
  padding: 4px 8px;
`;

const PropertyActive = styled.span<any>`
  &:before {
    display: block;
    ${({ isActive }) =>
      isActive
        ? css`
            content: "Active";
            color: ${({ theme }) => theme.white};
          `
        : css`
            content: "Inactive";
            color: red;
          `}
  }
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const getLabel = ({
  name,
  address,
}: {
  name: string;
  address: { [key: string]: any };
}) => {
  const title = `"${name}"`;
  if (typeof address === "string") {
    // eslint-disable-next-line
    //  console.warn("getLabel address is string", address);
    return [title, address].join(", ");
  }
  return [title, address.street, address.city].filter(Boolean).join(", ");
};
interface IProps {
  ownedProperties: any[];
  setProperties: (...args: any) => any;
  userDetails?: { [key: string]: any };
  setOwnedProperties: (...args: any) => any;
}

const PropertySelector: React.FC<IProps> = (props) => {
  //  state = { properties: [] };
  const [properties, setNewProperties] = useState<any[]>([]);
  const [deleteId, setDeleteId] = useState<number | string | null>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleRemoveAssignedProperty = async (propertyId: number | string) => {
    try {
      setLoading(true);
      const userCode = props.userDetails?.code;
      const newOwnedProperties = props.ownedProperties?.filter(
        (property) => property?.id !== propertyId,
      );
      const newOwnedPropertyIds = newOwnedProperties?.map(
        (property) => property?.id,
      );
      await linkProperty(userCode, "owner", newOwnedPropertyIds, true);
      props.setOwnedProperties(newOwnedProperties);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.warn(err);
    } finally {
      setDeleteId(null);
      setLoading(false);
    }
  };

  const renderExisting = () => {
    const removeExistProperty = (propertyId: any) => {
      setDeleteId(propertyId);
    };
    if (props.ownedProperties.length) {
      return (
        <Fragment>
          <H4>Properties already attached to this user:</H4>
          <List data-test="owned-properties">
            {props.ownedProperties.map((property) => {
              return (
                <PropertyItem key={`existing-${property.code}`}>
                  {getLabel(property)}
                  <Flex>
                    <PropertyActive isActive={property.isActive} />
                    <Button
                      label="Remove"
                      my={0}
                      onClick={() => removeExistProperty(property?.id)}
                      styling={BUTTON_INLINE}
                      size={BUTTON_SMALL}
                    />
                  </Flex>
                </PropertyItem>
              );
            })}
          </List>
        </Fragment>
      );
    }
    return null;
  };

  const renderSelect = () => {
    const addProperty = (event: any, property: any) => {
      const error = props.ownedProperties.find(
        ({ code }) => code === property.id,
      );
      if (error) {
        return alert("User is already attached to that property");
      }

      const doubleClick = properties.find(({ id }) => id === property.id);
      if (doubleClick) {
        // user has selected a property twice, remove it on second click
        removeProperty(property);
        return;
      }

      setNewProperties([...properties, property]);
    };

    const removeProperty = (property: any) => {
      const newProperties = properties.filter(({ id }) => id !== property.id);
      setNewProperties(newProperties);
    };

    useEffect(() => {
      props.setProperties(properties);
    }, [JSON.stringify(properties)]);
    return (
      <Fragment>
        <H4>Attach new properties to this user:</H4>
        <List data-test="pending-properties">
          {properties.length ? (
            properties.map((property: any) => {
              return (
                <PropertyItem key={property.id}>
                  {getLabel(property)}
                  <Button
                    label="Remove"
                    my={0}
                    onClick={() => removeProperty(property)}
                    styling={BUTTON_INLINE}
                    size={BUTTON_SMALL}
                  />
                </PropertyItem>
              );
            })
          ) : (
            <PropertyItem>Select a property below...</PropertyItem>
          )}
        </List>
        <Wrapper>
          <PropertyList
            // @ts-ignore
            autoFocus={false}
            clearCache={true}
            handleClick={addProperty}
            lazyLoad={false}
            limit={4}
            showButtons={false}
          />
        </Wrapper>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {renderExisting()}
      {renderSelect()}
      <DeletePopup
        id={deleteId}
        loading={loading}
        title="Remove property"
        content="Are you sure you want to remove this property?"
        handleClose={() => setDeleteId(null)}
        handleDelete={handleRemoveAssignedProperty}
      />
    </Fragment>
  );
};

export default PropertySelector;
